import React from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// eslint-disable-next-line import/order
import envConfig from './config/envConfig';
import { chatInit, chatUpdateUserAttributes } from '@a24group/chat-lib-twilio';
import reactDebug from '@a24group/react-debug-core/debugger';
import { appReady, registerPostMessageListener } from '@a24group/react-ember-iframecore/externalComms';
import { createTheme, ThemeProvider } from './app/lib/theme/theme';
import './app/lib/logger/logger';
import App from './app/app/app';
import './index.scss';
import { getAuth } from './app/data/auth/auth';

reactDebug.version = envConfig.version;

const initializeChat = async () => {
  const auth = await getAuth();
  chatInit({
    userType: 'chatterbox',
    accessToken: auth.accessToken,
    context: auth.context,
    accessTokenRefresh: async () => (await getAuth(true)).accessToken,
    chatApiDomain: envConfig.REACT_APP_SS_API_DOMAIN,
    chatApiSecondaryDomain: envConfig.REACT_APP_CHAT_DOMAIN,
    userDisplayName: auth.displayName,
    chatVersion: 1,
    candidateId: auth.contextType === 'candidate' ? auth.contextId : undefined,
    userId: auth.userId,
  });
  registerPostMessageListener(
    ({ data }) => {
      chatUpdateUserAttributes(data);
    },
    'user_attributes',
  );
};

/**
 * Application entry point
 */

const run = () => {
  ReactDOM.render(
    <ThemeProvider theme={createTheme()}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </ThemeProvider>,
    document.getElementById('react-app-container'),
  );
  appReady();
  initializeChat();
};

if (['complete', 'loaded', 'interactive'].includes(document.readyState) && document.body)
  run();
else
  window.addEventListener('DOMContentLoaded', run, false);
