import {
  chatLogEnable,
  chatLogExtra,
  chatLogFilter,
  chatLogPipeCallback,
} from '@a24group/chat-lib-twilio';
import registerCachedSetting, {
  reactDebug,
} from '@a24group/react-debug-settings/settings';
import envConfig from '../../../config/envConfig';

const chatLoggerSettings = registerCachedSetting('chatterbox_chat_logger', {
  extra: false,
  filter: undefined,
  enabled: Boolean(envConfig.IS_LOCAL),
});

const chatLoggerSettingsValue = chatLoggerSettings.get();
chatLogExtra(chatLoggerSettingsValue.extra);
chatLogFilter(chatLoggerSettingsValue.filter);
chatLogEnable(chatLoggerSettingsValue.enabled);

const setSettingValue = (key, value) => {
  const settings = chatLoggerSettings.get();
  settings[key] = value;
  chatLoggerSettings.set(settings);
  return value;
};

const enable = (value) => chatLogEnable(setSettingValue('enabled', Boolean(value)));

const logExtra = (value) => chatLogExtra(setSettingValue('extra', Boolean(value)));

const setFilter = (value) => chatLogFilter(setSettingValue('filter', value));

reactDebug.chatLogger = {
  enable,
  logExtra,
  setFilter,
};

chatLogPipeCallback((message, priority) => {
  switch (priority) {
    case 1:
      console.error(message);
      break;
    case 2:
      console.warn(message);
      break;
    default:
      console.log(message);
  }
});
