import envConfig, { setEnvConfig } from '@a24group/react-context-envconfig';

type TEnvConfig = {
  IS_LOCAL?: boolean,
  IS_DEVELOPMENT?: boolean,
  REACT_APP_BASE_URL: string,
  REACT_VIDEO_CALLS_BASE_URL?: string,
  REACT_APP_SS_API_DOMAIN?: string,
  REACT_APP_CHAT_DOMAIN?: string,
  REACT_APP_API_DOMAIN: string,
  REACT_APP_AUTH_DOMAIN: string,
  REACT_APP_FRAME_SOURCES: string[],
};

// @ts-ignore
setEnvConfig(webpackEnvConfig);

export default envConfig as TEnvConfig;
