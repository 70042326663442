import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import { RoundedAvatar } from '@a24group/react-ui-user';
import styles from './agencyListItem.scss';

const AgencyListItem = React.memo(({
  dataKey, displayName, avatarUrl, className, isSelected, onClick,
}) => {
  const handleItemClick = useCallback(() => {
    if (onClick)
      onClick('agency', dataKey, displayName, avatarUrl);
  }, [dataKey, displayName, onClick, avatarUrl]);

  return (
    <Fragment>
      <ListItem
        button
        component="li"
        key={dataKey}
        className={className}
        data-key={`agencyListItem_${dataKey}`}
        selected={isSelected}
        onClick={handleItemClick}
      >
        <div data-testid="agencyListItem_mainElement" className={styles.holder}>
          <RoundedAvatar url={avatarUrl} size={64} />
          <div className={styles.body}>
            {displayName}
          </div>
        </div>
      </ListItem>
    </Fragment>
  );
});

const propStructure = {
  displayName: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
};

AgencyListItem.propStructure = propStructure;

AgencyListItem.propTypes = {
  ...AgencyListItem.propStructure,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AgencyListItem;

export {
  propStructure,
};
