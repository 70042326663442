import retry from 'async-retry';
import { log } from '@a24group/react-debug-logger';
import { getAuthContext, systemUserAuthEndpoint } from '@a24group/react-context-globals';

let authorization = null;
const setAuthorization = (auth) => {
  authorization = auth;
};

const performWebAuth = async (authProcessCallback = (processed) => processed) => {
  try {
    const auth = await coreFetch(systemUserAuthEndpoint, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include',
    }).then((res) => res.json());

    const _auth = authProcessCallback({
      userId: auth.context.sUserId,
      accessToken: auth.accessToken,
      contextCategory: auth.context.objReferenceId.sResource,
      context: getAuthContext(auth.context.objReferenceId.sResource, auth.context.objReferenceId.mId),
    }, auth);

    setAuthorization(_auth);

    return _auth;
  } catch (err) {
    log(['error fetching web auth', err], 'error', 1);
    throw err;
  }
};

const getAuth = () => new Promise((resolve) => {
  const interval = window.setInterval(() => {
    if (authorization) {
      resolve(authorization);
      clearInterval(interval);
    }
  }, 100);
});

const coreFetch = async (url, options = null, config) => {
  if (!url)
    throw new Error('URL is required');

  if (config?.includeAuth) {
    const auth = await retry(getAuth);
    options = options || { headers: {} };
    options.credentials = 'include';
    options.headers.Context = auth?.context;
    options.headers.Authorization = `Bearer ${auth?.accessToken}`;
  }

  return retry(async (bail) => {
    const response = await fetch(url, options);
    const { ok, status } = response;

    if (ok && [200, 201, 202, 203, 204, 205, 206].indexOf(status) !== -1)
      return response;
    if (![408, 502, 503, 504].includes(status))
      return bail(response);
    throw response;
  }, {
    retries: config?.retries || 0,
  });
};

export {
  coreFetch,
  performWebAuth,
  setAuthorization,
};
