import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  chatConversationMessagesRegister,
  chatConversationMessagesLoadMore,
  chatConversationMessagesSend,
  chatConversationMessagesTyping,
  chatConversationMessagesRetry,
  chatConversationMessagesFocused,
  chatConversationStart,
  chatConversationMessagesClosedRegister,
  chatConversationMessagesClosedReload,
} from '@a24group/chat-lib-twilio';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import useResizeHandler from '@a24group/react-hooks-legacy/resizeHandler';
import { ActionableError } from '@a24group/react-ui-progress';
import usePostMessageListener from '@a24group/react-hooks-window/postMessageListener';
import { ChatBar, MediaFileUploadForm } from '@a24group/react-chatui-window';
import { ChatMessages } from '@a24group/react-chatui-messages';
import A24GroupHeader from '../chatHeaders/a24GroupHeader/a24GroupHeader';
import styles from './chatWindowWidget.scss';
import { messagesConverter } from './chatWindow.dataConverters';

const CHAT_WINDOW_ERROR = {
  failure: Symbol('chatWindowError:failure'),
  conversationDoesNotExist: Symbol('chatWindowError:conversationDoesNotExist'),
  conversationCreateFailure: Symbol('chatWindowError:conversationCreateFailure'),
};

const minTextLineHeight = 18;

const ChatWindow = React.memo(({
  className,
  onCloseChat,
  overrideChatBarMessage,
  onClickBack,
  headerDisplayName,
  conversation,
  isFocused,
  isClosedConversation = false,
  chatEntityData,
  onCreateConversation,
  headerAvatarUrl,
}) => {
  const chatWindowRef = useRef(null);

  // This is used to track the height of the window and inform the chatBar about what its max height should be
  //  as a result (about 50%)
  const [windowHeight, setWindowHeight] = useState(500);

  // handle resize events and update chatWindowsHeight state
  useResizeHandler((height) => {
    setWindowHeight(height);
  }, chatWindowRef);

  // This is used to track the height of the text field in the chat bar as it, itself reports it.
  //  This is used to adjust the height of messages panel to fill the remaining space.
  const [currentTextFieldHeight, setCurrentTextFieldHeight] = useState(minTextLineHeight);

  // Used to update our knowledge of the text fields height
  const handleTextSizeChange = useCallback((textFieldHeight) => {
    setCurrentTextFieldHeight(textFieldHeight < minTextLineHeight ? minTextLineHeight : textFieldHeight);
  }, []);

  const conversationId = isClosedConversation ? conversation?.backendConversationId : conversation?.conversationId;
  const retryChatConversationStartRef = useRef();

  const [isLoading, setIsLoading] = useState(!!conversationId);
  const [chatWindowError, setChatWindowError] = useState();
  const [conversationData, setConversationData] = useState();

  const [canLoadMoreMessages, setCanLoadMoreMessages] = useState(!!conversationId && !isClosedConversation);
  const [showMoreMessagesLoading, setShowMoreMessagesLoading] = useState(false);
  const [showMoreMessagesError, setShowMoreMessagesError] = useState(false);

  const [forceTextHeightUpdate, setForceTextHeightUpdate] = useState();
  const [forceScrollUpdate, setForceScrollUpdate] = useState();

  const [snackbar, setSnackbar] = useState();

  const [selectedFile, setSelectedFile] = useState();

  // open chatterbox event
  usePostMessageListener(() => {
    setForceTextHeightUpdate(Math.random());
    setForceScrollUpdate(Math.random());
  }, 'opened_chatbox', []);

  const displayName = conversationData?.conversation?.name || headerDisplayName;
  const avatarUrl = conversationData?.conversation?.avatar || headerAvatarUrl;
  const conversationIsActive = !!conversationData?.messages && !conversationData?.conversationEnded
    && !isClosedConversation;
  const showChatBar = conversationId
    ? conversationIsActive && !chatWindowError && !isLoading
    : !isLoading && !chatWindowError;
  const conversationDoesNotExist = chatWindowError === CHAT_WINDOW_ERROR.conversationDoesNotExist;

  const messages = useMemo(
    () => messagesConverter(conversationData?.messages || []),
    [conversationData],
  );

  const handleTyping = useCallback(() => {
    if (conversationId)
      chatConversationMessagesTyping(conversationId);
  }, [conversationId]);

  const handleLoadMoreMessages = useCallback(async () => {
    setShowMoreMessagesLoading(true);
    setShowMoreMessagesError(false);
    const moreMessagesSuccess = await chatConversationMessagesLoadMore(conversationId);
    setShowMoreMessagesLoading(false);
    if (moreMessagesSuccess === false)
      setShowMoreMessagesError(true);
    if (moreMessagesSuccess === undefined)
      setCanLoadMoreMessages(false);
  }, [conversationId]);

  useEffect(() => {
    if (conversationId && !isClosedConversation) {
      chatConversationMessagesFocused(conversationId, isFocused);
      return () => chatConversationMessagesFocused(conversationId, false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    if (!conversationId)
      return () => {};

    let unregisterChatConversationMessages = () => {};
    const onData = (data) => {
      setIsLoading(false);
      setChatWindowError(null);
      setConversationData((state) => ({ ...state, ...data }));
    };
    const onFailure = (_conversationDoesNotExist) => {
      setIsLoading(false);
      setChatWindowError(
        _conversationDoesNotExist
          ? CHAT_WINDOW_ERROR.conversationDoesNotExist
          : CHAT_WINDOW_ERROR.failure,
      );
    };
    const onLoading = () => {
      setIsLoading(true);
      setChatWindowError(null);
    };

    if (isClosedConversation) {
      unregisterChatConversationMessages = chatConversationMessagesClosedRegister({
        conversationId,
        onData,
        onFailure,
        onLoading,
      });
    } else {
      unregisterChatConversationMessages = chatConversationMessagesRegister({
        conversationId,
        isFocused,
        onData,
        onFailure,
        onLoading,
        onChatFailure: () => { /* Handled by parent component */ },
        onChatLoading: () => { /* Handled by parent component */ },
      });
    }

    return () => unregisterChatConversationMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  const handleConversationCreate = useCallback((response) => {
    if (response.conversationId)
      onCreateConversation({ conversationId: response.conversationId });
    else if (response.conversationAlreadyExists)
      onClickBack();
    else if (response.retryFunction) {
      setIsLoading(false);
      setChatWindowError(CHAT_WINDOW_ERROR.conversationCreateFailure);
      retryChatConversationStartRef.current = response.retryFunction;
    }
  }, [onCreateConversation, onClickBack]);

  const sendMessage = useCallback((message, media) => {
    chatConversationMessagesSend(conversationId, message, media);
    setForceScrollUpdate(Math.random());
  }, [conversationId]);

  const handleUploadBuffer = useCallback((file) => {
    setSelectedFile(file);
  }, []);

  const handleUploadFile = useCallback((data) => {
    sendMessage(undefined, { media: selectedFile, mime: selectedFile.type, filename: data.fileName });
    setSelectedFile(undefined);
  }, [selectedFile, sendMessage]);

  const handleCancelFileUploadForm = useCallback(() => {
    setSelectedFile(undefined);
  }, []);

  const handleFileUploadError = useCallback((error) => {
    setSnackbar({ message: error.message });
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(null);
  }, []);

  const handleSendMessage = useCallback((message) => {
    if (conversationId)
      sendMessage(message);
    else {
      setIsLoading(true);
      chatConversationStart(
        message,
        {},
        chatEntityData?.id,
        chatEntityData?.type,
        chatEntityData?.name,
      ).then(handleConversationCreate);
    }
  }, [conversationId, sendMessage, chatEntityData, handleConversationCreate]);

  const handleChatErrorAction = useCallback(() => {
    if (chatWindowError === CHAT_WINDOW_ERROR.conversationDoesNotExist)
      onClickBack();
    else if (chatWindowError === CHAT_WINDOW_ERROR.conversationCreateFailure) {
      setIsLoading(true);
      setChatWindowError(null);
      retryChatConversationStartRef.current().then(handleConversationCreate);
    } else if (isClosedConversation)
      chatConversationMessagesClosedReload(conversationId);
    else
      chatConversationMessagesRetry(conversationId, true);
  }, [chatWindowError, onClickBack, isClosedConversation, conversationId, handleConversationCreate]);

  return (
    <div ref={chatWindowRef} className={`${className} ${styles.chatWindow}`}>
      <Snackbar
        key={snackbar?.message}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!snackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        className={styles.snackbar}
      >
        <SnackbarContent message={snackbar?.message} className={styles.snackbarContent} />
      </Snackbar>

      <A24GroupHeader
        className={styles.a24GroupHeader}
        onBack={onClickBack}
        onClose={onCloseChat}
        displayName={displayName}
        avatarUrl={avatarUrl}
        typingParticipants={conversationData?.conversation?.typingParticipantsString}
        participants={conversationData?.conversation?.participantsNames}
      />

      <div
        className={styles.main}
        style={{
          // 109px will be used for small headers and 209px for large ones. This value is
          // derived by adding the height of the chatbar at its minimum size + the height of the header
          // eslint-disable-next-line max-len
          height: `calc(100% - 109px + ${showChatBar ? 0 : 49}px + ${minTextLineHeight}px - ${currentTextFieldHeight}px)`,
        }}
      >
        {chatWindowError && (
          <div className={styles.error}>
            <ActionableError
              message={conversationDoesNotExist ? 'Conversation not found' : undefined}
              actionTitle={conversationDoesNotExist ? 'Back' : undefined}
              onClickAction={handleChatErrorAction}
            />
          </div>
        )}
        {!chatWindowError && (
          <ChatMessages
            messages={messages}
            onSendMessage={handleSendMessage}
            forceScrollUpdate={currentTextFieldHeight + showChatBar ? '_1' : '_0'}
            forceScrollToBottomOnOpening={forceScrollUpdate}
            useFader={false}
            isLoadingMessages={isLoading}
            conversationIsSelected
            enableSuggestionsInteraction
            allowDropFile={conversationIsActive}
            onUploadBuffer={handleUploadBuffer}
            onFileUploadError={handleFileUploadError}
            canLoadMoreMessages={canLoadMoreMessages}
            isLoadMoreMessagesError={showMoreMessagesError}
            isLoadMoreMessagesLoading={showMoreMessagesLoading}
            onLoadMoreMessages={handleLoadMoreMessages}
            contextName="candidate"
            conversationIsClosed={!conversationIsActive}
            disabledMediaInteraction={isClosedConversation ? false : !conversationIsActive}
          />
        )}
      </div>
      {showChatBar ? (
        <ChatBar
          className={styles.chatBar}
          onSendMessage={handleSendMessage}
          onTyping={handleTyping}
          onTextSizeChange={handleTextSizeChange}
          minTextLineHeight={minTextLineHeight}
          maxHeight={Math.floor(windowHeight / 2 - 100)}
          useTabs={false}
          useSendButton
          useIconSend
          messageOverride={overrideChatBarMessage}
          forceTextHeightUpdate={forceTextHeightUpdate}
          onUploadBuffer={handleUploadBuffer}
          showUploadIcon={conversationIsActive}
          onFileUploadError={handleFileUploadError}
        />
      ) : null}
      <MediaFileUploadForm
        file={selectedFile}
        onUpload={handleUploadFile}
        onError={handleFileUploadError}
        onCancel={handleCancelFileUploadForm}
      />
    </div>
  );
});

ChatWindow.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  isClosedConversation: PropTypes.bool,
  conversation: PropTypes.shape({
    conversationId: PropTypes.string.isRequired,
    backendConversationId: PropTypes.string,
  }),
  className: PropTypes.string,
  onCloseChat: PropTypes.func,
  onClickBack: PropTypes.func,
  onCreateConversation: PropTypes.func.isRequired,
  overrideChatBarMessage: ChatBar.propTypes.messageOverride,
  headerDisplayName: PropTypes.string,
  headerAvatarUrl: PropTypes.string,
  chatEntityData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default ChatWindow;
