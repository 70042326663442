import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const CloseButton = React.memo(({ className, onClick }) => (
  <IconButton onClick={onClick} className={className}>
    <CloseIcon />
  </IconButton>
));

CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CloseButton;
