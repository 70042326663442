import { registerPostMessageListener } from '@a24group/react-ember-iframecore/externalComms';
import uid from '@a24group/react-debug-core/uid';

const frameCallbacks = new Map();

registerPostMessageListener(
  (data) => {
    if (frameCallbacks.has(data.data.callbackId)) {
      const obj = frameCallbacks.get(data.data.callbackId);

      obj.callback();

      if (!obj.callMultiple)
        frameCallbacks.delete(data.data.callbackId);
    }
  },
  'callback_by_id',
);

const registerFrameCallback = (callback, callMultiple = false) => {
  const callbackId = uid();

  frameCallbacks.set(callbackId, { callback, callMultiple });

  return { callbackId, deRegister: () => frameCallbacks.delete(callbackId) };
};

export default registerFrameCallback;
