// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agencyLoader--SZVGO,
.chatWindow--NR3Fl,
.agencySelectionWidget--IMyb2,
.conversationsWidget--ebIII {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/app/app.scss"],"names":[],"mappings":"AAAA;;;;EAIE,YAAA;AACF","sourcesContent":[":local(.agencyLoader),\n:local(.chatWindow),\n:local(.agencySelectionWidget),\n:local(.conversationsWidget) {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agencyLoader": `agencyLoader--SZVGO`,
	"chatWindow": `chatWindow--NR3Fl`,
	"agencySelectionWidget": `agencySelectionWidget--IMyb2`,
	"conversationsWidget": `conversationsWidget--ebIII`
};
export default ___CSS_LOADER_EXPORT___;
