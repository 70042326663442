import { parseAttributes, UriBuilder } from "./util";
import { Logger } from "./logger";
import { Conversation } from "./conversation";
import {
  CancellablePromise,
  McsClient,
  MediaCategory,
} from "@twilio/mcs-client";
import { Media } from "./media";
import { Participant } from "./participant";
import {
  AggregatedDeliveryDescriptor,
  AggregatedDeliveryReceipt,
} from "./aggregated-delivery-receipt";
import {
  validateTypes,
  validateTypesAsync,
  nonEmptyString,
  nonEmptyArray,
} from "@twilio/declarative-type-validator";
import { json } from "./interfaces/rules";
import { Network } from "./services/network";
import { RestPaginator } from "./rest-paginator";
import { DetailedDeliveryReceipt } from "./detailed-delivery-receipt";
import { Paginator } from "./interfaces/paginator";
import { Configuration } from "./configuration";
import { CommandExecutor } from "./command-executor";
import { EditMessageRequest } from "./interfaces/commands/edit-message";
import { MessageResponse } from "./interfaces/commands/message-response";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import isEqual from "lodash.isequal";
import { JSONValue } from "./types";
import { ResponseMeta } from "./interfaces/commands/response-meta";
import { DeliveryReceiptResponse } from "./interfaces/commands/delivery-receipt-response";
import { deprecated } from "@twilio/deprecation-decorator";
import { ContentData, parseVariant } from "./content-template";

type MessageEvents = {
  updated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
};

const log = Logger.scope("Message");
const XHR =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  global["XMLHttpRequest"] || require("xmlhttprequest").XMLHttpRequest;

interface MessageState {
  sid: string;
  index: number;
  author: string | null;
  subject: string | null;
  contentSid: string | null;
  body: string | null;
  dateUpdated: Date | null;
  lastUpdatedBy: string | null;
  attributes: JSONValue;
  timestamp: Date | null;
  type: MessageType;
  media: Media | null;
  medias: Media[] | null;
  participantSid: string | null;
  aggregatedDeliveryReceipt: AggregatedDeliveryReceipt | null;
}

interface MessageServices {
  mcsClient: McsClient;
  network: Network;
  commandExecutor: CommandExecutor;
}

interface MessageLinks {
  self: string;
  conversation: string;
  messages_receipts: string;
}

/**
 * The reason for the `updated` event being emitted by a message.
 */
type MessageUpdateReason =
  | "body"
  | "lastUpdatedBy"
  | "dateCreated"
  | "dateUpdated"
  | "attributes"
  | "author"
  | "deliveryReceipt"
  | "subject";

/**
 * Type of a message.
 */
type MessageType = "text" | "media";

interface MessageUpdatedEventArgs {
  message: Message;
  updateReasons: MessageUpdateReason[];
}

export interface MessageData {
  sid: string;
  text?: string;
  type?: MessageType;
  author: string | null;
  subject: string | null;
  contentSid: string | null;
  lastUpdatedBy?: string | null;
  attributes?: JSONValue;
  dateUpdated: string;
  timestamp?: string;
  medias?: Media[];
  media?: Media;
  memberSid?: string;
  delivery?: AggregatedDeliveryDescriptor;
}

/**
 * A message in a conversation.
 */
class Message extends ReplayEventEmitter<MessageEvents> {
  /**
   * Conversation that the message is in.
   */
  public readonly conversation: Conversation;

  private readonly links: MessageLinks;
  private readonly configuration: Configuration;
  private readonly services: MessageServices;

  private state: MessageState;

  /**
   * @internal
   */
  constructor(
    index: number,
    data: MessageData,
    conversation: Conversation,
    links: MessageLinks,
    configuration: Configuration,
    services: MessageServices
  ) {
    super();

    this.conversation = conversation;

    this.links = links;
    this.configuration = configuration;
    this.services = services;

    this.state = {
      sid: data.sid,
      index: index,
      author: data.author,
      subject: data.subject,
      contentSid: data.contentSid,
      body: data.text ?? null,
      timestamp: data.timestamp ? new Date(data.timestamp) : null,
      dateUpdated: data.dateUpdated ? new Date(data.dateUpdated) : null,
      lastUpdatedBy: data.lastUpdatedBy ?? null,
      attributes: parseAttributes(
        data.attributes,
        `Got malformed attributes for the message ${data.sid}`,
        log
      ),
      type: data.type ?? "text",
      media:
        data.type === "media" && data.media
          ? new Media(data.media, this.services)
          : null,
      medias:
        data.type === "media" && data.medias
          ? data.medias.map((m) => new Media(m, this.services))
          : data.type === "media" && data.media && !data.medias
          ? [
              new Media(
                { ...data.media, category: "media" } as Media,
                this.services
              ),
            ]
          : null,
      participantSid: data.memberSid ?? null,
      aggregatedDeliveryReceipt: data.delivery
        ? new AggregatedDeliveryReceipt(data.delivery)
        : null,
    };
  }

  /**
   * Fired when the properties or the body of the message has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Message} message - the message in question
   *     * {@link MessageUpdateReason}[] updateReasons - array of reasons for the update
   */
  static readonly updated = "updated";

  /**
   * The server-assigned unique identifier for the message.
   */
  public get sid(): string {
    return this.state.sid;
  }

  /**
   * Name of the user that sent the message.
   */
  public get author(): string | null {
    return this.state.author;
  }

  /**
   * Message subject. Used only in email conversations.
   */
  public get subject(): string | null {
    return this.state.subject;
  }

  /**
   * Unique identifier of {@link ContentTemplate} for this message.
   */
  public get contentSid(): string | null {
    return this.state.contentSid;
  }

  /**
   * Body of the message.
   */
  public get body(): string | null {
    return this.state.body;
  }

  /**
   * Date this message was last updated on.
   */
  public get dateUpdated(): Date | null {
    return this.state.dateUpdated;
  }

  /**
   * Index of this message in the conversation's list of messages.
   *
   * By design, the message indices may have arbitrary gaps between them,
   * that does not necessarily mean they were deleted or otherwise modified - just that
   * messages may have some non-contiguous indices even if they are being sent immediately one after another.
   *
   * Trying to use indices for some calculations is going to be unreliable.
   *
   * To calculate the number of unread messages, it is better to use the Read Horizon API.
   * See {@link Conversation.getUnreadMessagesCount} for details.
   */
  public get index(): number {
    return this.state.index;
  }

  /**
   * Identity of the last user that updated the message.
   */
  public get lastUpdatedBy(): string | null {
    return this.state.lastUpdatedBy;
  }

  /**
   * Date this message was created on.
   */
  public get dateCreated(): Date | null {
    return this.state.timestamp;
  }

  /**
   * Custom attributes of the message.
   */
  public get attributes(): JSONValue {
    return this.state.attributes;
  }

  /**
   * Type of the message.
   */
  public get type(): MessageType {
    return this.state.type;
  }

  /**
   * One of the attached media (if present).
   * @deprecated Use attachedMedia instead. Note that the latter is now an array.
   */
  public get media(): Media | null {
    return this.state.media;
  }

  /**
   * Return all media attachments, except email body/history attachments, without temporary urls.
   */
  public get attachedMedia(): Array<Media> | null {
    return this.getMediaByCategories(["media"]);
  }

  /**
   * The server-assigned unique identifier of the authoring participant.
   */
  public get participantSid(): string | null {
    return this.state.participantSid;
  }

  /**
   * Aggregated information about the message delivery statuses across all participants of a conversation..
   */
  public get aggregatedDeliveryReceipt(): AggregatedDeliveryReceipt | null {
    return this.state.aggregatedDeliveryReceipt;
  }

  /**
   * @deprecated
   * Return a (possibly empty) array of media matching a specific set of categories.
   * Allowed category is so far only 'media'.
   * @param categories Array of categories to match.
   * @returns Array of media descriptors matching given categories.
   */
  @deprecated("getMediaByCategory", "getMediaByCategories")
  public getMediaByCategory(
    categories: Array<MediaCategory>
  ): Array<Media> | null {
    return this.getMediaByCategories(categories);
  }

  /**
   * Return a (possibly empty) array of media matching a specific set of categories.
   * Allowed category is so far only 'media'.
   * @param categories Array of categories to match.
   * @returns Array of media descriptors matching given categories.
   */
  public getMediaByCategories(categories: MediaCategory[]): Media[] | null {
    return (this.state.medias ?? []).filter((m) =>
      categories.includes(m.category)
    );
  }

  /**
   * Get a media descriptor for an email body attachment of a provided type.
   * Allowed body types are returned in the Conversation.limits().emailBodiesAllowedContentTypes array.
   * @param type Type of email body to request, defaults to `text/plain`.
   */
  @validateTypes([nonEmptyString, "undefined"])
  public getEmailBody(type = "text/plain"): Media | null {
    return (
      this.getMediaByCategories(["body"])
        ?.filter((m) => m.contentType == type)
        .shift() ?? null
    );
  }

  /**
   * Get a media descriptor for an email history attachment of a provided type.
   * Allowed body types are returned in the Conversation.limits().emailHistoriesAllowedContentTypes array.
   * @param type Type of email history to request, defaults to `text/plain`.
   */
  @validateTypes([nonEmptyString, "undefined"])
  public getEmailHistory(type = "text/plain"): Media | null {
    return (
      this.getMediaByCategories(["history"])
        ?.filter((m) => m.contentType == type)
        .shift() ?? null
    );
  }

  _update(data) {
    const updateReasons: MessageUpdateReason[] = [];

    if (
      (data.text || typeof data.text === "string") &&
      data.text !== this.state.body
    ) {
      this.state.body = data.text;
      updateReasons.push("body");
    }

    if (data.subject && data.subject !== this.state.subject) {
      this.state.subject = data.subject;
      updateReasons.push("subject");
    }

    if (data.lastUpdatedBy && data.lastUpdatedBy !== this.state.lastUpdatedBy) {
      this.state.lastUpdatedBy = data.lastUpdatedBy;
      updateReasons.push("lastUpdatedBy");
    }

    if (data.author && data.author !== this.state.author) {
      this.state.author = data.author;
      updateReasons.push("author");
    }

    if (
      data.dateUpdated &&
      new Date(data.dateUpdated).getTime() !==
        (this.state.dateUpdated && this.state.dateUpdated.getTime())
    ) {
      this.state.dateUpdated = new Date(data.dateUpdated);
      updateReasons.push("dateUpdated");
    }

    if (
      data.timestamp &&
      new Date(data.timestamp).getTime() !==
        (this.state.timestamp && this.state.timestamp.getTime())
    ) {
      this.state.timestamp = new Date(data.timestamp);
      updateReasons.push("dateCreated");
    }

    const updatedAttributes = parseAttributes(
      data.attributes,
      `Got malformed attributes for the message ${this.sid}`,
      log
    );
    if (!isEqual(this.state.attributes, updatedAttributes)) {
      this.state.attributes = updatedAttributes;
      updateReasons.push("attributes");
    }

    const updatedAggregatedDelivery = data.delivery;
    const currentAggregatedDelivery = this.state.aggregatedDeliveryReceipt;
    const isUpdatedAggregateDeliveryValid =
      !!updatedAggregatedDelivery &&
      !!updatedAggregatedDelivery.total &&
      !!updatedAggregatedDelivery.delivered &&
      !!updatedAggregatedDelivery.failed &&
      !!updatedAggregatedDelivery.read &&
      !!updatedAggregatedDelivery.sent &&
      !!updatedAggregatedDelivery.undelivered;
    if (isUpdatedAggregateDeliveryValid) {
      if (!currentAggregatedDelivery) {
        this.state.aggregatedDeliveryReceipt = new AggregatedDeliveryReceipt(
          updatedAggregatedDelivery
        );
        updateReasons.push("deliveryReceipt");
      } else if (
        !currentAggregatedDelivery._isEquals(updatedAggregatedDelivery)
      ) {
        currentAggregatedDelivery._update(updatedAggregatedDelivery);
        updateReasons.push("deliveryReceipt");
      }
    }

    if (updateReasons.length > 0) {
      this.emit("updated", { message: this, updateReasons: updateReasons });
    }
  }

  /**
   * Get the participant who is the author of the message.
   */
  public async getParticipant(): Promise<Participant> {
    let participant: Participant | null = null;
    if (this.state.participantSid) {
      participant = await this.conversation
        .getParticipantBySid(this.state.participantSid)
        .catch(() => {
          log.debug(
            `Participant with sid "${this.participantSid}" not found for message ${this.sid}`
          );
          return null;
        });
    }
    if (!participant && this.state.author) {
      participant = await this.conversation
        .getParticipantByIdentity(this.state.author)
        .catch(() => {
          log.debug(
            `Participant with identity "${this.author}" not found for message ${this.sid}`
          );
          return null;
        });
    }
    if (participant) {
      return participant;
    }
    let errorMesage = "Participant with ";
    if (this.state.participantSid) {
      errorMesage += "SID '" + this.state.participantSid + "' ";
    }
    if (this.state.author) {
      if (this.state.participantSid) {
        errorMesage += "or ";
      }
      errorMesage += "identity '" + this.state.author + "' ";
    }
    if (errorMesage === "Participant with ") {
      errorMesage = "Participant ";
    }
    errorMesage += "was not found";
    throw new Error(errorMesage);
  }

  /**
   * Get the delivery receipts of the message.
   */
  public async getDetailedDeliveryReceipts(): Promise<
    DetailedDeliveryReceipt[]
  > {
    let paginator: Paginator<DetailedDeliveryReceipt> =
      await this._getDetailedDeliveryReceiptsPaginator();
    let detailedDeliveryReceipts: DetailedDeliveryReceipt[] = paginator.items;

    while (paginator.hasNextPage) {
      paginator = await paginator.nextPage();
      detailedDeliveryReceipts = [
        ...detailedDeliveryReceipts,
        ...paginator.items,
      ];
    }

    return detailedDeliveryReceipts;
  }

  /**
   * Remove the message.
   */
  public async remove(): Promise<Message> {
    await this.services.commandExecutor.mutateResource(
      "delete",
      this.links.self
    );

    return this;
  }

  /**
   * Edit the message body.
   * @param body New body of the message.
   */
  @validateTypesAsync("string")
  public async updateBody(body: string): Promise<Message> {
    await this.services.commandExecutor.mutateResource<
      EditMessageRequest,
      MessageResponse
    >("post", this.links.self, {
      body,
    });

    return this;
  }

  /**
   * Edit the message attributes.
   * @param attributes New attributes.
   */
  @validateTypesAsync(json)
  public async updateAttributes(attributes: JSONValue): Promise<Message> {
    await this.services.commandExecutor.mutateResource<
      EditMessageRequest,
      MessageResponse
    >("post", this.links.self, {
      attributes:
        typeof attributes !== "undefined"
          ? JSON.stringify(attributes)
          : undefined,
    });

    return this;
  }

  /**
   * @deprecated
   * Get content URLs for all media attachments in the given set using a single operation.
   * @param contentSet Set of media attachments to query content URLs.
   */
  @deprecated("attachTemporaryUrlsFor", "getTemporaryContentUrlsForMedia")
  public async attachTemporaryUrlsFor(
    contentSet: Media[] | null
  ): Promise<Media[]> {
    // We ignore existing mcsMedia members of each of the media entries.
    // Instead we just collect their sids and pull new descriptors from a mediaSet GET endpoint.
    const sids = contentSet?.map((m) => m.sid);
    if (this.services.mcsClient && sids) {
      return (await this.services.mcsClient.mediaSetGet(sids)).map((item) => {
        return new Media(item, this.services);
      });
    } else {
      throw new Error("Media Content Service is unavailable");
    }
  }

  /**
   * Get content URLs for all media attachments in the given set using a single operation.
   * @param contentSet Set of media attachments to query content URLs.
   */
  @validateTypesAsync(nonEmptyArray("media", Media))
  public getTemporaryContentUrlsForMedia(
    contentSet: Media[]
  ): CancellablePromise<Map<string, string>> {
    // We ignore existing mcsMedia members of each of the media entries.
    // Instead we just collect their sids and pull new descriptors from a mediaSet GET endpoint.
    const sids = contentSet.map((m) => m.sid);
    return this.getTemporaryContentUrlsForMediaSids(sids);
  }

  /**
   * Get content URLs for all media attachments in the given set of media sids using a single operation.
   * @param mediaSids Set of media sids to query for the content URL.
   */
  @validateTypesAsync(nonEmptyArray("strings", "string"))
  public getTemporaryContentUrlsForMediaSids(
    mediaSids: string[]
  ): CancellablePromise<Map<string, string>> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      const mediaGetRequest = this.services.mcsClient.mediaSetGetContentUrls(
        mediaSids ?? []
      );

      if (!this.services.mcsClient || !mediaSids) {
        reject(new Error("Media Content Service is unavailable"));
        return;
      }

      onCancel(() => {
        mediaGetRequest.cancel();
      });

      try {
        const urls = await mediaGetRequest;
        resolve(urls);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Get content URLs for all media attached to the message.
   */
  public getTemporaryContentUrlsForAttachedMedia(): CancellablePromise<
    Map<string, string>
  > {
    const media = this.attachedMedia;
    const sids = media?.map((m) => m.sid) ?? [];
    return this.getTemporaryContentUrlsForMediaSids(sids);
  }

  private async _getDetailedDeliveryReceiptsPaginator(options?: {
    pageToken?: string;
    pageSize?: number;
  }): Promise<Paginator<DetailedDeliveryReceipt>> {
    const messagesReceiptsUrl = this.configuration.links.messagesReceipts
      .replace("%s", this.conversation.sid)
      .replace("%s", this.sid);
    const url = new UriBuilder(messagesReceiptsUrl)
      .arg("PageToken", options?.pageToken as string)
      .arg("PageSize", options?.pageSize as number)
      .build();
    const response = await this.services.network.get<
      { delivery_receipts: DeliveryReceiptResponse[] } & ResponseMeta
    >(url);

    return new RestPaginator<DetailedDeliveryReceipt>(
      response.body.delivery_receipts.map(
        (x) => new DetailedDeliveryReceipt(x)
      ),
      (pageToken, pageSize) =>
        this._getDetailedDeliveryReceiptsPaginator({ pageToken, pageSize }),
      response.body.meta.previous_token,
      response.body.meta.next_token
    );
  }

  /**
   * Get the {@link ContentData} for this message. Resolves to `null` when
   * {@link Message.contentSid} is null.
   */
  public getContentData(): CancellablePromise<ContentData | null> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      if (this.state.contentSid === null) {
        resolve(null);
        return;
      }

      const bodies = this.getMediaByCategories(["body"]);

      if (bodies === null) {
        resolve(null);
        return;
      }

      const twilioPrefix = "application/x-vnd.com.twilio.rich.";
      const filteredMedias = bodies.filter((media) =>
        media.contentType.startsWith(twilioPrefix)
      );

      if (filteredMedias.length === 0) {
        resolve(null);
        return;
      }

      const contentMedia = filteredMedias[0];
      const urlPromise = contentMedia.getContentTemporaryUrl();

      onCancel(() => {
        urlPromise.cancel();
      });

      let url: string | null;

      try {
        url = await urlPromise;
      } catch (e) {
        reject(e);
        return;
      }

      if (url === null) {
        resolve(null);
        return;
      }

      const jsonStringPromise = new Promise<string>((resolve, reject) => {
        let isCancelled = false;
        const xhr = new XHR();
        xhr.open("GET", url ?? "", true);
        xhr.responseType = "text";
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4 || isCancelled) {
            return;
          }
          resolve(xhr.responseText);
        };
        xhr.onerror = () => {
          reject(xhr.statusText);
        };
        onCancel(() => {
          isCancelled = true;
          xhr.abort();
          reject(new Error("XHR has been aborted"));
        });
        xhr.send();
      });

      let json;

      try {
        const jsonString = await jsonStringPromise;
        json = JSON.parse(jsonString);
      } catch (e) {
        reject(e);
        return;
      }

      const dataType = contentMedia.contentType
        .replace(twilioPrefix, "")
        .replace(".", "/");

      resolve(parseVariant(dataType, json.data));
    });
  }
}

export {
  Message,
  MessageServices,
  MessageType,
  MessageUpdateReason,
  MessageUpdatedEventArgs,
};
