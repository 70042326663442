import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { chatInitRegister, chatRestart } from '@a24group/chat-lib-twilio';
import { ActionableLoader } from '@a24group/react-ui-progress';
import styles from './chatLoaderOverlay.scss';

const ChatLoaderOverlay = ({ children }) => {
  const hasLoadedRef = useRef(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isReloading, setIsReloading] = useState(false);

  useEffect(() => {
    const unregisterChatInit = chatInitRegister({
      onSuccess: () => {
        setIsError(false);
        setIsLoading(false);
        setIsReloading(false);
        hasLoadedRef.current = true;
      },
      onFailure: () => {
        setIsError(true);
        setIsLoading(false);
        setIsReloading(false);
      },
      onLoading: () => {
        setIsError(false);
        setIsLoading(true);
        setIsReloading(hasLoadedRef.current);
      },
    });
    return () => unregisterChatInit();
  }, []);

  return (
    <Fragment>
      {(isLoading || isReloading || isError) && (
        <ActionableLoader
          variant={isReloading ? 'translucent' : 'white'}
          className={styles.overlay}
          isError={isError}
          onReload={chatRestart}
        />
      )}
      {children}
    </Fragment>
  );
};

export default ChatLoaderOverlay;
