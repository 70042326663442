import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import List from '@material-ui/core/List';
import useDataLoader from '@a24group/react-hooks-datasourcing/dataLoader';
import agencyGroupSelectionLogo from './agencyGroupSelectionLogo.png';
import A24GroupHeader from '../chatHeaders/a24GroupHeader/a24GroupHeader';
import styles from './agencySelectionWidget.scss';

import AgencyListItem from './agencyListItem/agencyListItem';
import { getAgencyAssociations } from '../../data/agencyAssociations/agencyAssociations';

const AgencySelectionWidget = React.memo(({
  className, onClose, onClickBack, onAgencySelect,
}) => {
  const dataLoader = useDataLoader({
    loadData: getAgencyAssociations,
    dependencies: [],
  });

  const agencies = dataLoader.data?.agencies;
  const agencyGroup = dataLoader.data?.agencyGroups?.[0];

  const handleAgencyGroupSelect = useCallback(
    () => onAgencySelect('agencygroup', agencyGroup.dataKey, agencyGroup.displayName, agencyGroup.avatarUrl),
    [onAgencySelect, agencyGroup],
  );

  return (
    <div className={`${className} ${styles.agencySelectionWidget}`}>
      <A24GroupHeader
        className={styles.a24GroupHeader}
        onBack={onClickBack}
        onClose={onClose}
      />
      {dataLoader.render({
        className: styles.dataLoader,
        children: () => (
          <div className={styles.container}>
            <div className={styles.floatingStartConversation}>
              <div className={styles.startConversationLogo}>
                <img src={agencyGroupSelectionLogo} alt="" className={styles.startConversationLogoImage} />
              </div>
              <div className={styles.startConversation}>
                Chat directly to our recruitment adviser if you have any questions regarding <b>compliance</b>
              </div>
              <footer className={styles.agenciesFooter}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.startConversationButton}
                  startIcon={<SendIcon />}
                  key={agencyGroup.dataKey}
                  data-key={agencyGroup.dataKey}
                  onClick={handleAgencyGroupSelect}
                >
                  Start Conversation
                </Button>
              </footer>
            </div>
            {agencies.length > 0 ? (
              <div className={styles.floatingAgencyList}>
                <div className={styles.selectAgency}>
                  To speak directly to an agency for any <b>booking or pay </b>
                  related queries, please select the agency you want to chat with
                </div>
                <List
                  className={styles.agencyList_override}
                >
                  {[...agencies.values()].map((agency) => (
                    <AgencyListItem
                      key={agency.dataKey}
                      dataKey={agency.dataKey}
                      displayName={agency.displayName}
                      avatarUrl={agency.avatarUrl}
                      className={styles.agencyListItem_override}
                      onClick={onAgencySelect}
                    />
                  ))}
                </List>
              </div>
            ) : null}
          </div>
        ),
      })}
    </div>
  );
});

AgencySelectionWidget.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onClickBack: PropTypes.func,
  onAgencySelect: PropTypes.func,
};

export default AgencySelectionWidget;
