/* eslint-disable camelcase */
const agenciesConverter = (agencies) => (agencies ? agencies.map((agency) => ({
  displayName: agency.display_name,
  dataKey: agency.agency_id,
  avatarUrl: agency.avatar_url,
})) : []);

const agencyGroupsConverter = (agencyGroups) => (agencyGroups ? agencyGroups.map((agencyGroup) => ({
  displayName: agencyGroup?.display_name,
  dataKey: agencyGroup?.agency_group_id,
  avatarUrl: agencyGroup?.avatar_url,
})) : []);

export {
  agenciesConverter,
  agencyGroupsConverter,
};
/* eslint-enable camelcase */
