import React from 'react';
import PropTypes from 'prop-types';
import CloseButton from '../../closeButton/closeButton';
import styles from './a24GroupHeaderLarge.scss';

const A24GroupHeader = React.memo(({ className, displayName, onClose }) => (
  <header className={`${styles.header} ${className}`}>
    <CloseButton className={styles.closeButton} onClick={onClose} />
    <span className={styles.title}>Hi {displayName}</span>
    <span className={styles.subtitle}>
      Ask us anything, or share your feedback
    </span>
  </header>
));

A24GroupHeader.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
  displayName: PropTypes.string,
};

export default A24GroupHeader;
