import { postMessage } from '@a24group/react-ember-iframecore/externalComms';
import desktopNotificationImage from './desktopNotification.png';
import registerFrameCallback from '../frameCallback';

// kept this here, just in case it doesnt cause a problem for code anywhere else.
document.domain = 'staffshift.com';

const desktopNotification = (title, message, onClick, forceSingleton = true) => {
  const callbackId = onClick ? registerFrameCallback(onClick).callbackId : undefined;
  postMessage({
    type: 'desktop_notification',
    data: {
      title,
      body: message,
      icon: desktopNotificationImage,
      badge: desktopNotificationImage,
      forceSingleton,
      callbackId,
    },
  });
};

export default desktopNotification;
