import React, { useCallback, useEffect, useState } from 'react';
import { chatLocalPushNotificationsRegister } from '@a24group/chat-lib-twilio';
import { advancedLogger } from '@a24group/react-debug-logger';
import { postMessage } from '@a24group/react-ember-iframecore/externalComms';
import usePromise from '@a24group/react-hooks-legacy/promise';
import useWindowHasFocus from '@a24group/react-hooks-window/windowHasFocus';
import useDataLoader from '@a24group/react-hooks-datasourcing/dataLoader';
import usePostMessageListener from '@a24group/react-hooks-window/postMessageListener';
import ChatWindow from '../widgets/chatWindow/chatWindowWidget';
import ConversationsWidget from '../widgets/conversations/conversationsWidget';
import AgencySelectionWidget from '../widgets/agencySelection/agencySelectionWidget';
import styles from './app.scss';
import ChatLoaderOverlay from '../widgets/chatLoaderOverlay/chatLoaderOverlay';
import { getAuth } from '../data/auth/auth';
import { getAgencyAssociations } from '../data/agencyAssociations/agencyAssociations';
import { openChatterbox } from '../lib/externalComms/externalComms';
import desktopNotification from '../lib/desktopNotification';

const { rlog: glog } = advancedLogger('general');

const screenTypes = {
  agencyLoader: Symbol('agencyLoader'),
  chatWindow: Symbol('chatWindow'),
  conversations: Symbol('conversations'),
  agencySelection: Symbol('agencySelection'),
};

const loadAgencyGroup = () => getAgencyAssociations().then((response) => response.agencyGroups?.[0]);

// //////////////////////////////   Main React app and state setups

const App = React.memo(() => {
  const [overrideChatBarMessage, setOverrideChatBarMessage] = useState();
  const [activeScreen, setActiveScreen] = useState(screenTypes.conversations);
  const [chatterboxIsOpen, setChatterboxIsOpen] = useState(false);
  const [headerDisplayName, setHeaderDisplayName] = useState();
  const [headerAvatarUrl, setHeaderAvatarUrl] = useState();
  const [selectedAgencyData, setSelectedAgencyData] = useState();
  const [authDetails] = usePromise(getAuth, { dependencies: [] });
  const [selectedConversation, setSelectedConversation] = useState();
  const [conversationListTabIndex, setConversationListTabIndex] = useState(0);
  const [openedConvFromClosed, setOpenedConvFromClosed] = useState(false);
  const windowHasFocus = useWindowHasFocus();
  const isCandidateContext = authDetails?.contextType === 'candidate';

  const selectConversation = useCallback((conversation, isFromClosedConv = false) => {
    glog([`conversation selected ${conversation}`]);
    setOpenedConvFromClosed(isFromClosedConv);
    setSelectedConversation(conversation);
    setHeaderDisplayName(conversation.conversationName);
    setHeaderAvatarUrl(conversation.conversationAvatar);
    setActiveScreen(screenTypes.chatWindow);
    setOverrideChatBarMessage(null);
    setSelectedAgencyData(null);
  }, []);

  useEffect(() => {
    const chatLocalPushNotificationsUnregister = chatLocalPushNotificationsRegister(
      (unreadConversations, unreadConversationsCount) => {
        if (unreadConversations) {
          unreadConversations.forEach((unreadConversation) => desktopNotification(
            unreadConversation.conversationName,
            unreadConversation.lastMessageText,
            () => {
              selectConversation(unreadConversation);
              openChatterbox();
            },
            false,
          ));
        }
        postMessage({
          type: 'unread_messages',
          data: { count: unreadConversationsCount },
        });
      },
    );
    return () => chatLocalPushNotificationsUnregister();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startChatWithAgency = useCallback((agency) => {
    setSelectedAgencyData(agency);
    setHeaderDisplayName(agency.name);
    setHeaderAvatarUrl(agency.avatarUrl);
    setActiveScreen(screenTypes.chatWindow);
  }, []);

  const handleAgencyGroupOnLoaded = useCallback((agencyGroup) => {
    glog(['agency group loader on loaded', agencyGroup]);
    startChatWithAgency({
      type: 'agencygroup', id: agencyGroup.dataKey, name: agencyGroup.displayName, avatarUrl: agencyGroup.avatarUrl,
    });
  }, [startChatWithAgency]);

  const agencyGroupDataLoader = useDataLoader({
    deferUntilRender: true,
    loadData: loadAgencyGroup,
    onLoaded: handleAgencyGroupOnLoaded,
    dependencies: [],
  });

  // ////////////////// Utility functions

  const openConversationsList = useCallback(() => {
    glog(['back button clicked']);
    setSelectedConversation(null);
    setHeaderDisplayName(undefined);
    setHeaderAvatarUrl(undefined);
    setActiveScreen(screenTypes.conversations);
    setOverrideChatBarMessage(null);
  }, []);

  const openNewChat = useCallback((displayName, avatarUrl) => {
    glog(['open new chat triggered']);
    setHeaderDisplayName(displayName);
    setHeaderAvatarUrl(avatarUrl);
    setOverrideChatBarMessage(null);
    setActiveScreen(screenTypes.chatWindow);
  }, []);

  // open chatterbox event
  usePostMessageListener(() => {
    setChatterboxIsOpen(true);
  }, 'opened_chatbox', []);

  // close chatterbox event
  usePostMessageListener(() => {
    setChatterboxIsOpen(false);
  }, 'closed_chatbox', []);

  // Listen for `populate_chatbar_input` window post message
  usePostMessageListener(({ data }) => {
    if (data.contextId && data.contextType && data.contextName) {
      startChatWithAgency({
        type: data.contextType.toLowerCase(),
        id: data.contextId,
        name: data.contextName,
      });
    } else if (isCandidateContext && agencyGroupDataLoader.data) {
      startChatWithAgency({
        type: 'agencygroup',
        id: agencyGroupDataLoader.data.dataKey,
        name: agencyGroupDataLoader.data.displayName,
      });
    } else if (isCandidateContext)
      setActiveScreen(screenTypes.agencyLoader);
    else
      setActiveScreen(screenTypes.chatWindow);

    setSelectedConversation(null);
    setOverrideChatBarMessage({
      message: data.message,
      orderReference: data.orderReference,
      orderId: data.orderId,
      shiftId: data.shiftId,
      agencyOrderId: data.agencyOrderId,
    });
  }, 'populate_chatbar_input', [isCandidateContext, agencyGroupDataLoader.data]);

  // ///////////////////// User interaction handlers

  const handleCloseChat = useCallback(() => {
    glog(['current closed the chat']);
    postMessage({ type: 'close_chatbox' });
  }, []);

  const handleClickNewConversation = useCallback(() => {
    glog(['new conversation initiated']);
    if (isCandidateContext)
      setActiveScreen(screenTypes.agencySelection);
    else
      openNewChat();
  }, [isCandidateContext, openNewChat]);

  const handleSelectAgency = useCallback((type, id, name, avatarUrl) => {
    glog(['new conversation initiated for agency', type, id]);
    setSelectedAgencyData({
      type, id, name, avatarUrl,
    });
    openNewChat(name, avatarUrl);
  }, [openNewChat]);

  const handleCreateConversation = useCallback((conversation) => {
    glog(['new conversation created', conversation]);
    if (activeScreen === screenTypes.chatWindow)
      selectConversation(conversation);
  }, [activeScreen, selectConversation]);

  // //////////////////// Render

  switch (activeScreen) {
    case screenTypes.agencyLoader:
      return agencyGroupDataLoader.render({
        className: styles.agencyLoader,
      });
    case screenTypes.chatWindow:
      return (
        <ChatLoaderOverlay>
          <ChatWindow
            key={selectedConversation?.conversationId}
            isClosedConversation={openedConvFromClosed}
            isFocused={chatterboxIsOpen && windowHasFocus}
            className={styles.chatWindow}
            conversation={selectedConversation}
            onCloseChat={handleCloseChat}
            onClickBack={openConversationsList}
            headerDisplayName={headerDisplayName}
            headerAvatarUrl={headerAvatarUrl}
            overrideChatBarMessage={overrideChatBarMessage}
            chatEntityData={selectedAgencyData}
            onCreateConversation={handleCreateConversation}
          />
        </ChatLoaderOverlay>
      );
    case screenTypes.agencySelection:
      return (
        <AgencySelectionWidget
          className={styles.agencySelectionWidget}
          onClose={handleCloseChat}
          onClickBack={openConversationsList}
          onAgencySelect={handleSelectAgency}
        />
      );
    case screenTypes.conversations:
      return (
        <ChatLoaderOverlay>
          <ConversationsWidget
            className={styles.conversationsWidget}
            isCandidateContext={isCandidateContext}
            userDisplayName={authDetails?.firstName}
            tabIndex={conversationListTabIndex}
            onClose={handleCloseChat}
            onConversationSelect={selectConversation}
            onClickNewConversation={handleClickNewConversation}
            onTabIndexChange={setConversationListTabIndex}
          />
        </ChatLoaderOverlay>
      );
    default:
      return null;
  }
});

export default App;
