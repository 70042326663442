import { getSystemCandidateSettingsEndpoint } from '@a24group/react-context-globals';
import { coreFetch } from '../../utils';
import { getAuth } from '../auth/auth';
import { agenciesConverter, agencyGroupsConverter } from './agencyAssociations.dataConverters';

let data = null;
let promise = null;

const getAgencyAssociations = async (refresh) => {
  if (!refresh && data)
    return data;

  const auth = await getAuth();

  if (!promise) {
    promise = coreFetch(
      getSystemCandidateSettingsEndpoint(auth.contextId),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      {
        retries: 3,
        includeAuth: true,
      },
    ).then((response) => response.json());
  }

  const response = await promise.finally(() => {
    promise = null;
  });

  data = {
    agencies: agenciesConverter(response.agencies),
    agencyGroups: agencyGroupsConverter(response.agency_groups),
  };

  return data;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getAgencyAssociations,
};
