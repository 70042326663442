import {
  messageBubbleUserTypes,
  messageBubbleDeliveryStatuses,
  messageTypeMediaMediaTypes,
  messageBubbleMessageTypes,
  messageBubbleMediumTypes,
} from '@a24group/react-chatui-messages';
import { helperDateGetMessageLastDate } from '@a24group/chat-lib-twilio';

const deliveryStatuses = {
  ...messageBubbleDeliveryStatuses,
  delivered: messageBubbleDeliveryStatuses.sent,
};

const getMediaType = (message) => {
  if (['application', 'text'].includes(message.messageMediaCategory))
    return messageTypeMediaMediaTypes[message.messageMediaType];
  return messageTypeMediaMediaTypes[message.messageMediaCategory];
};

const isChatterboxUser = (message) => ['sms', 'whatsapp', 'you', 'chatterbox'].includes(
  message.senderType,
);

const messagesConverter = (messages) => messages.map((message) => ({
  ...message,
  id: message.messageId,
  message: message.messageText,
  deliveryStatus: deliveryStatuses[message.messageDeliveryStatus],
  userType: messageBubbleUserTypes[message.senderType],
  messageType: messageBubbleMessageTypes[message.messageType],
  mediumType: messageBubbleMediumTypes[message.messageMedium],
  timestamp: message.messageDate?.getTime(),
  messageTime: helperDateGetMessageLastDate(message.messageDate, true),
  align: isChatterboxUser(message) ? 'right' : 'left',
  userDisplayName: message.senderName,
  userAvatarUrl: message.senderAvatar,
  formatMessage: message.messageType === 'text',
  augments: {
    format: message.messageAttributes?.format,
    richContent: message.messageAttributes?.richContent,
  },
  mediaType: getMediaType(message),
  mediaSizeDisplay: message.messageMediaSizeDisplay,
  deliveryFailedText: message.messageDeliveryFailedText,
  contentType: message.messageMediaMime,
  getContentUrl: message.messageMediaGetTempUrl,
  mediaFileName: message.messageMediaFileName,
  mediaFileExtension: message.messageMediaExtension?.toUpperCase(),
}));

export {
  // eslint-disable-next-line import/prefer-default-export
  messagesConverter,
};
