import { MESSAGE_MEDIUM } from '@a24group/react-chatui-conversations';
import { helperDateGetMessageLastDate } from '@a24group/chat-lib-twilio';

const conversationsConverter = (conversations) => conversations.map((conversation) => ({
  ...conversation,
  conversationName: conversation.conversationName,
  dataKey: conversation.conversationId,
  avatarUrl: conversation.conversationAvatar,
  typingMembers: conversation.typingParticipants,
  lastMessageText: conversation.lastMessageText,
  lastMessageDeliveryStatus: conversation.lastMessageDeliveryStatus,
  lastMessageIsUnread: conversation.lastMessageIsUnread,
  lastMessageTime: helperDateGetMessageLastDate(
    conversation.lastMessageDate,
    false,
  ),
  lastMessageMedium: MESSAGE_MEDIUM.includes(conversation.lastMessageMedium)
    ? conversation.lastMessageMedium
    : undefined,
  typing: Boolean(conversation.typingParticipants?.length),
}));

export {
  // eslint-disable-next-line import/prefer-default-export
  conversationsConverter,
};
