import React, {
  Fragment, useCallback, useLayoutEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import { Send, Refresh } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
  chatConversationListInit,
  chatConversationListRegister,
  chatConversationListClosedRegister,
  chatConversationListClosedReload,
} from '@a24group/chat-lib-twilio';
import { ActionableError } from '@a24group/react-ui-progress';
import { ConversationListItemChatterbox } from '@a24group/react-chatui-conversations';
import A24GroupHeaderLarge from '../chatHeaders/a24GroupHeaderLarge/a24GroupHeaderLarge';
import styles from './conversationsWidget.scss';
import { conversationsConverter } from './conversationsWidget.dataConverters';

const ConversationsWidget = React.memo(({
  className,
  isCandidateContext = false,
  tabIndex = 0,
  onTabIndexChange,
  onConversationSelect,
  onClickNewConversation,
  userDisplayName,
  onClose,
}) => {
  const isClosedConversations = tabIndex === 1;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [conversations, setConversations] = useState([]);

  const handleTabChange = useCallback((event, newValue) => {
    onTabIndexChange(newValue);
  }, [onTabIndexChange]);

  const handleReload = useCallback(() => {
    if (!isClosedConversations)
      chatConversationListInit();
    else
      chatConversationListClosedReload();
  }, [isClosedConversations]);

  useLayoutEffect(() => {
    let unregisterChatConversationList = () => {};
    const onData = (data) => {
      setIsError(false);
      setIsLoading(false);
      setConversations(conversationsConverter(data));
    };
    const onFailure = () => {
      setIsError(true);
      setIsLoading(false);
    };
    const onLoading = () => {
      setIsError(false);
      setIsLoading(true);
    };

    if (!isClosedConversations) {
      // Register for open conversations

      // Save to call init multiple times since it has internal checks and do nothing if already setup
      chatConversationListInit();

      unregisterChatConversationList = chatConversationListRegister({
        onData,
        onFailure,
        onLoading,
        onChatFailure: () => { /* Handled by parent component */ },
        onChatLoading: () => { /* Handled by parent component */ },
      });
    } else {
      // Register for closed conversations
      unregisterChatConversationList = chatConversationListClosedRegister({
        onData,
        onFailure,
        onLoading,
      });
      // For now we will reload the closed conversations data each time the tab is selected.
      // This is because the closed conversations list is not updated in real time due to backend
      // having to sync with twilio etc.
      // For now we go with the assumption that the user is aware of a conversation that closed
      // when they go to that tab and then we automatically reload the data.
      // In addition the user also has a refresh action available when then closed tab is selected
      chatConversationListClosedReload();
    }

    return () => unregisterChatConversationList();
  }, [isClosedConversations]);

  return (
    <div className={`${className} ${styles.conversationsWidget}`}>
      <A24GroupHeaderLarge
        className={styles.a24GroupHeaderLarge}
        onClose={onClose}
        displayName={userDisplayName}
      />
      <div className={styles.floatingConversationsList}>
        <Box className={styles.conversationsHeaderContainer}>
          <header className={styles.conversationsHeader}>
            Your Conversations
          </header>
          {!isLoading && isClosedConversations ? (
            <Box pt={1} pr={1}>
              <IconButton component="span" onClick={handleReload}>
                <Refresh />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        {isCandidateContext ? (
          <Fragment>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              value={tabIndex}
              onChange={handleTabChange}
            >
              <Tab label="Open" id="tab-0" aria-controls="tabpanel-0" />
              <Tab label="Closed" id="tab-1" aria-controls="tabpanel-1" />
            </Tabs>
            <Divider variant="fullWidth" />
          </Fragment>
        ) : null}
        <div className={styles.conversationsBody}>
          {isLoading && (
            <div className={styles.loaderHolder}>
              <CircularProgress />
            </div>
          )}
          {isError && (
            <ActionableError onClickAction={handleReload} />
          )}
          {!isLoading && !isError && (
            conversations?.length ? (
              <List className={styles.conversationsList_override}>
                {conversations.map((conversation) => (
                  <ConversationListItemChatterbox
                    key={conversation.dataKey}
                    dataKey={conversation.dataKey}
                    candidateDisplayName={conversation.conversationName}
                    avatarUrl={conversation.avatarUrl}
                    lastMessageMedium={conversation.lastMessageMedium}
                    lastMessageText={conversation.lastMessageText}
                    lastMessageTime={conversation.lastMessageTime}
                    lastMessageIsUnread={conversation.lastMessageIsUnread}
                    lastMessageDeliveryStatus={conversation.lastMessageDeliveryStatus}
                    onClick={() => onConversationSelect(conversation, isClosedConversations)}
                    typing={conversation.typing}
                  />
                ))}
              </List>
            ) : (
              <div className={styles.noConversations}>
                No conversations
              </div>
            )
          )}
        </div>
        <Divider variant="middle" className={styles.footerDivider} />
        <footer className={styles.conversationsFooter}>
          <Button
            variant="outlined"
            color="primary"
            className={styles.newConversationButton}
            startIcon={<Send />}
            onClick={onClickNewConversation}
          >
            New Conversation
          </Button>
        </footer>
      </div>
    </div>
  );
});

ConversationsWidget.propTypes = {
  onConversationSelect: PropTypes.func,
  onClose: PropTypes.func,
  onClickNewConversation: PropTypes.func,
  className: PropTypes.string,
  userDisplayName: PropTypes.string,
  isCandidateContext: PropTypes.bool,
  tabIndex: PropTypes.number,
  onTabIndexChange: PropTypes.func,
};

export default ConversationsWidget;
