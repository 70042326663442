import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const createTheme = (override = {}) => createMuiTheme({
  palette: {
    primary: {
      main: '#3f51b5',
      light: '#757de8',
      dark: '#002984',
    },
    secondary: {
      main: '#ffab00',
      light: '#ffdd4b',
      dark: '#c67c00',
    },
    background: {
      paper: '#fff',
      default: '#f6f6f6',
    },
  },
  ...override,
});

export {
  createTheme,
  ThemeProvider,
};
