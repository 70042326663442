import { performWebAuth } from '../../utils';

let data = null;
let promise = null;

const getAuth = async (refresh) => {
  if (!refresh && data)
    return data;

  if (!promise) {
    promise = performWebAuth((processed, response) => ({
      ...processed,
      contextId: response.context.objReferenceId.mId,
      contextType: response.context.objReferenceId.sResource.toLowerCase(),
      firstName: response.identity.sFirstName,
      displayName: `${response.identity.sFirstName} ${response.identity.sLastName}`,
    }));
  }

  data = await promise.finally(() => {
    promise = null;
  });

  return data;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getAuth,
};
