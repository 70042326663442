import { postMessage } from '@a24group/react-ember-iframecore/externalComms';

const openChatterbox = () => {
  postMessage({ type: 'open_chatbox' });
};

const placeHolder = null;

export {
  openChatterbox,
  placeHolder,
};
