import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { Box, Tooltip } from '@material-ui/core';
import { RoundedAvatar } from '@a24group/react-ui-user';
import CloseButton from '../../closeButton/closeButton';
import styles from './a24GroupHeader.scss';

const A24GroupHeader = React.memo(
  ({
    className,
    onClose,
    onBack,
    displayName,
    avatarUrl,
    participants,
    typingParticipants,
  }) => (
    <header className={`${styles.header} ${className}`}>
      <IconButton className={styles.backButton} onClick={onBack}>
        <KeyboardArrowLeft />
      </IconButton>
      <CloseButton className={styles.closeButton} onClick={onClose} />
      <Box className={styles.main}>
        <RoundedAvatar
          size={44}
          fontSize={24}
          url={avatarUrl}
          className={styles.avatar}
          title={displayName}
          letter={displayName?.[0]?.toUpperCase()}
        />
        <Box overflow="hidden">
          <h1 className={styles.displayName}>{displayName}</h1>
          {typingParticipants && (
            <Tooltip title={typingParticipants} placement="right">
              <Box mt={0.5} className={styles.typingIndicator}>{typingParticipants}</Box>
            </Tooltip>
          )}
          {!typingParticipants && participants && (
            <Tooltip title={participants} placement="right">
              <Box mt={0.5} className={styles.participants}>{participants}</Box>
            </Tooltip>
          )}
        </Box>
      </Box>
    </header>
  ),
);

A24GroupHeader.defaultProps = {
  displayName: 'A24 Group',
};

A24GroupHeader.propTypes = {
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string,
  participants: PropTypes.string,
  typingParticipants: PropTypes.string,
};

export default A24GroupHeader;
